import { graphql, Link } from "gatsby"
import React from "react"
import Modal from "react-modal"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import { Swipeable } from "react-swipeable"


const popupStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.8)',
    zIndex: 100,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    maxWidth: '100vw',
    border: 0,
    color: '#ffffff',
    zIndex: 101,
  }
}

Modal.setAppElement('#___gatsby')

class NotationTemplate extends React.Component {

  images = []

  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      images: [],
      imageIndex: 0,
      imageClassNames: []
    }
  }

  componentDidMount() {
    // if (typeof window == 'undefined') return;
    const $imageLinks = document.querySelectorAll('.image-link')
    console.log(`image link.${$imageLinks}`)
    if (!$imageLinks) {
      return;
    }
    for (let link of $imageLinks) {
      link.addEventListener('click', this.openImage)
    }
  }

  openImage = (event) => {
    console.log(`click.${event.target.id}`)
    const id = event.target.id
    const images = this.images.filter(
      im => {
        console.log(`image.${im.frontmatter.id} ${im.frontmatter.title}`)
        return im.frontmatter.id.startsWith(id)
      }
    )
    if (!images.length) {
      console.log(`no image.${id}`)
      return
    }
    const names = []
    images.forEach(
      (im, i) => {
        if (!i) {
          names.push('selected')
        } else {
          names.push('')
        }
      }
    )
    this.setState(
      {
        images: images,
        imageIndex: 0,
        imageClassNames: names
      }
    )
    this.openModal()
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  selectImage = (index) => {
    console.log(`select image.${index}`)
    if (index < 0 || index >= this.images.length) {
      return
    }
    const names = []
    this.state.imageClassNames.forEach(
      (name, i) => {
        names.push(i === index ? 'selected' : '')
      }
    )
    this.setState(
      {
        imageIndex: index,
        imageClassNames: names
      }
    )
  }

  render() {
    const post = this.props.data.markdownRemark
    const videoRoot = this.props.data.site.siteMetadata.videoRoot
    const imageRoot = this.props.data.site.siteMetadata.imageRoot
    const lang = post.fields.slug.split('/')[1]
    const siteTitle = translate('site.name', lang)
    const { previous, next, relations, world, images, altNotation, worlds } = this.props.pageContext
    console.log(`world.${world}`)
    console.log(`relations.${relations.length}`)
    console.log(`images.${images.length}`)
    this.images = images
    const headerSubTitle = world.frontmatter.title
    const hasVideo = post.frontmatter.videos.length > 0
    const worldName = world.fields.slug.split('/')[3]
    const preloadImages = []
    for (let image of images) {
      preloadImages.push(`materials/${image.frontmatter.id}.png`)
    }
    preloadImages.push(`worlds/${worldName}_back.jpg`)

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug} isReverse={true} world={true}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          images={preloadImages}
          slug={post.fields.slug}
        />
        <article className="main-content">
          <div className={`sub-content overview notation ${hasVideo ? 'has-video' : ''}`}
            style={
              {
                backgroundImage: `url(${imageRoot}/worlds/${worldName}_back.jpg)`,
                backgroundSize: `cover`,
                backgroundPosition: `bottom right`,
              }
            }
          >
            <div>
              <h1>{post.frontmatter.title}</h1>
              <hr></hr>
              <h2>{
                altNotation && (altNotation.frontmatter.title)
              }</h2>
              {
                !!post.frontmatter.videos.length && (
                  <div className="video">
                    {
                      post.frontmatter.videos.map(
                        (v, i) => {
                          return <video controls controlsList="nodownload" key={i}
                            poster={`${videoRoot}/${v.substring(0, v.length - 4)}.jpg`}>
                            <source src={`${videoRoot}/${v}`} type="video/mp4"></source>
                          </video>
                        }
                      )
                    }
                  </div>
                )
              }
            </div>
            <div className="control">
              <div className="control-actions">
                <div className="control-actions-prev">
                  {!previous && (
                    <span className="chevron-left disabled"></span>
                  )}
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev"
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                      }}><span className="chevron-left"></span></Link>
                  )}
                </div>
                <div className="control-actions-back">
                  <a href="../" rel="back"
                    style={{
                      boxShadow: `none`,
                      textDecoration: `none`,
                      color: `inherit`,
                    }}
                    title={world.frontmatter.title}>
                    <div className="navbar-burger">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </a>
                </div>
                <div className="control-actions-next">
                  {!next && (
                    <span className="chevron-right disabled"></span>
                  )}
                  {next && (
                    <Link to={next.fields.slug} rel="next"
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                      }}><span className="chevron-right"></span></Link>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="sub-content detail">
            <div className="text">
              <section>
                <h2>{translate('notation.notation', lang)}</h2>
                <div dangerouslySetInnerHTML={{ __html: post.html }} className={`inner selected`} />
                <div className={`inner explanation`}>
                  <div className="caption" style={
                    {
                      display: post.frontmatter.description ? `block` : `none`
                    }
                  }>{translate('notation.explanation', lang)}</div>
                  <div className="description">
                    {post.frontmatter.description}
                  </div>
                  <div className="caption" style={
                    {
                      display: relations.length > 0 ? `block` : `none`
                    }
                  }>{translate('notation.relation', lang)}</div>
                  {
                    relations.map(
                      (r, i) => {
                        const relationWorld = worlds.find(
                          w => w.fields.slug.split('/')[3] === r.fields.slug.split('/')[3]
                        )
                        return <div className="relation" key={i}>
                          <div className="description">
                            <span className={`world lang-${lang}`}>{relationWorld.frontmatter.title}</span>
                            {r.frontmatter.description}
                          </div>
                          <Link to={r.fields.slug}>{r.frontmatter.title}</Link>
                        </div>
                      }
                    )
                  }
                </div>
              </section>
            </div>
            <div className="control">
              <div className="control-actions">
                <div className="control-actions-prev">
                  {!previous && (
                    <span className="chevron-left"></span>
                  )}
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev"
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                      }}><span className="chevron-left"></span></Link>
                  )}
                </div>
                <div className="control-actions-back">
                  <a href="../" rel="back"
                    style={{
                      boxShadow: `none`,
                      textDecoration: `none`,
                      color: `inherit`,
                    }}>
                    <div className="navbar-burger">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </a>
                </div>
                <div className="control-actions-next">
                  {!next && (
                    <span className="chevron-right"></span>
                  )}
                  {next && (
                    <Link to={next.fields.slug} rel="next"
                      style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        color: `inherit`,
                      }}><span className="chevron-right"></span></Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </article>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={popupStyles}
          contentLabel="Image"
        >
          {
            !!this.state.images.length && (
              <div>
                <div className="image-container">
                  {
                    this.state.images.map(
                      (image, i) => {
                        return <div key={i} className={`image-box ${this.state.imageClassNames[i]}`}>
                          <img src={`${imageRoot}/materials/${image.frontmatter.id}.png`} alt={image.frontmatter.title}></img>
                          <div className="image-caption">
                            <div className="image-caption-items">
                              {
                                image.frontmatter.captions.map(
                                  (c, j) => {
                                    return <span className="image-caption-item" key={j}>{c} </span>
                                  }
                                )
                              }
                            </div>
                          </div>
                        </div>
                      }
                    )
                  }
                  <div>
                    <h3 className={`image-title ${lang === 'ja' ? 'lang-ja' : 'lang-en'}`}>{this.state.images[0].frontmatter.title}</h3>
                    <p className="image-descrption">{this.state.images[0].frontmatter.description}</p>
                  </div>
                </div>
                <div className="image-footer">
                  {
                    this.state.images.length > 1 && (
                      <Swipeable className="control"
                        onSwipedLeft={() => this.selectImage(this.state.imageIndex - 1)}
                        onSwipedRight={() => this.selectImage(this.state.imageIndex + 1)}
                      >
                        {!(this.state.imageIndex > 0) && (
                          <div>
                            <span className="chevron-left disabled"></span>
                            <span className="chevron-left disabled"></span>
                          </div>
                        )}
                        {this.state.imageIndex > 0 && (
                          <div className="prev" onClick={() => this.selectImage(this.state.imageIndex - 1)}>
                            <span className="chevron-left"></span>
                            <span className="chevron-left"></span>
                          </div>
                        )}
                        <div className="indicators">
                          {
                            this.state.images.map(
                              (im, i) => {
                                let indicatorClass = ''
                                if (i < this.state.imageIndex) {
                                  indicatorClass = 'past'
                                } else if (i === this.state.imageIndex) {
                                  indicatorClass = 'current'
                                }
                                return <span key={i} rel="image"
                                  className={`indicator fixed ${indicatorClass}`}
                                  onClick={() => this.selectImage(i)}
                                  style={
                                    {
                                      width: `calc(100% / ${this.state.images.length})`
                                    }
                                  }
                                  >
                                </span>
                              }
                            )
                          }
                        </div>
                        {!(this.state.imageIndex < this.state.images.length - 1 ) && (
                          <div>
                            <span className="chevron-right disabled"></span>
                            <span className="chevron-right disabled"></span>
                          </div>
                        )}
                        {this.state.imageIndex < this.state.images.length - 1 && (
                          <div className="next" onClick={() => this.selectImage(this.state.imageIndex + 1)}>
                            <span className="chevron-right"></span>
                            <span className="chevron-right"></span>
                          </div>
                        )}
                      </Swipeable>
                    )
                  }
                  <button onClick={this.closeModal}>Close</button>
                </div>
              </div>
            )
          }
        </Modal>
      </Layout >
    )
  }
}

export default NotationTemplate

export const pageQuery = graphql`
  query NotationBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        videoRoot
        imageRoot
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        videos
      }
    }
  }
`
